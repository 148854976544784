/* eslint-disable react-hooks/exhaustive-deps */
import dayGridPlugin from '@fullcalendar/daygrid'; // needed for dayClick
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import CloseIcon from '@material-ui/icons/Close';
import { forIn, get, isEmpty, isEqual, pickBy } from 'lodash';
import momentTimezone from 'moment-timezone';
import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  Loading,
  required,
  SelectInput,
  SimpleForm,
  Tab,
  TabbedShowLayout,
} from 'react-admin';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { DebounceInput } from 'react-debounce-input';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';
import { customDataProvider } from '../data-provider';
import {
  firstDayOfWeek,
  formatPhoneNumber,
  getAppointmentDateTime,
  getEndDate,
  getFormattedDate,
  getLocations,
  isBreakroom,
  isDayBirthday,
  isGss,
  isTherapist,
  showToast,
} from '../utils';
import {
  API_LIMIT,
  APPOINTMENT_STATUS,
  DATE_FORMAT,
  HEARD_ABOUT_US,
} from '../utils/constants';
import { AppointmentEdit } from './appointment-edit';
import './appointment.css';

export const AppointmentList = ({ permissions, ...props }) => {
  const [showModal, setShowModal] = useState(false);
  const [appointment, setAppointment] = useState(null);
  const [selectedDate, setSelectedDate] = useState(
    localStorage.getItem('selectedDate')
      ? new Date(localStorage.getItem('selectedDate'))
      : new Date(),
  );
  const [therapist, setTherapist] = useState('all');
  const [therapists, setTherapists] = useState(null);
  const [waiver, setWaiver] = useState(true);
  const [therapistsLoading, setTherapistsLoading] = useState(true);
  const [isAwaiting, setIsAwaiting] = useState(false);
  const defaultGridView = isTherapist(permissions)
    ? 'resourceTimeGridWeek'
    : 'resourceTimeGridDay';
  const [gridView, setGridView] = useState(defaultGridView);
  const [apptNotes, setApptNotes] = useState(appointment?.notes);
  const [guestNotes, setGuestNotes] = useState(appointment?.user?.guest_notes);
  const [heardAboutUs, setheardAboutUs] = useState();
  const [showLoader, setShowLoader] = useState(false);
  const [showLocationSelectorModal, setShowLocationSelectorModal] =
    useState(false);
  const [workLocations, setWorkLocations] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const calendarRef = React.useRef();
  const dateFormat = 'YYYY-MM-DDTHH:mm';
  const validateRequired = [required()];
  const [user, setUser] = useState(null);
  const [scheduleData, setScheduleData] = useState();
  const [therapistsListData, setTherapistsListData] = useState();
  const [appoitnmetsLists, setAppointmentsLists] = useState();
  const [fetchData, setFetchData] = useState(true);
  const [closePopup, setClosePopup] = useState(true);

  useEffect(() => {
    setWaiver(!!appointment?.user?.liability_waiver);
    setApptNotes(appointment?.notes);
    setGuestNotes(appointment?.user?.guest_notes);
    customDataProvider
      .getOne('guest', { id: appointment?.user?.id })
      .then(({ data }) => {
        setUser(data);
      });
  }, [appointment]);

  useEffect(() => {
    calendarRef?.current?.calendar.rerenderResources();
  }, [therapists]);

  useEffect(() => {
    if (
      appointment?.notes !== apptNotes && 
      appointment?.status !== APPOINTMENT_STATUS.CANCELLED &&
      appointment &&
      appointment?.id
    ) {
      const payload = {
        notes: apptNotes,
      };
      if (appointment?.notes !== apptNotes) {
        // setShowLoader(true);
        customDataProvider
          .update('appointment', {
            id: appointment?.id,
            action: 'edit',
            payload,
          })
          .then(({ data }) => {
            setApptNotes(data.notes);
            // setShowLoader( false );
            showToast('Appointment Notes updated successfully', 'success');

            setFetchData(true);
          })
          .catch(error => error && console.log('Something went wrong'));
      }
    }
  }, [apptNotes]);

  useEffect(() => {
    if (
      appointment?.status !== APPOINTMENT_STATUS.CANCELLED &&
      appointment &&
      appointment?.id &&
      appointment?.user?.guest_notes !== guestNotes
    ) {
      const userId = appointment?.user?.id;
      const body = {
        id: userId,
        guest_notes: guestNotes,
      };
      if (!isEmpty(pickBy(body))) {
        // setShowLoader(true);
        customDataProvider
          .update('user', body)
          .then(({ data }) => {
            setGuestNotes(data.guest_notes);
            // setShowLoader( false );
            showToast('Guest Notes updated successfully', 'success');
            setFetchData(true);
          })
          .catch(error => error && console.log('Something went wrong'));
      }
    }
  }, [guestNotes]);

  useEffect(() => {
    const _selectedLocation = localStorage.getItem('recentSelectedLocation')
      ? localStorage.getItem('recentSelectedLocation')
      : localStorage.getItem('selectedLocation');
    customDataProvider.getList('userlocations').then(data => {
      setWorkLocations(data.data);
    });
    setSelectedLocation(_selectedLocation);
  }, []);

  useEffect(() => {
    if (selectedLocation) {
      const filter = {
        location_id: selectedLocation,
      };

      customDataProvider.getList('therapist', { filter }).then(({ data }) => {
        handleTherapistFilter('all');
        setTherapistsListData(data);
        setFetchData(true);
      });
    }
  }, [selectedLocation]);

  useEffect(() => {
    if (therapistsListData && fetchData) {
      const startAt =
        therapist !== 'all' || isTherapist(permissions)
          ? firstDayOfWeek(selectedDate)
          : getFormattedDate(selectedDate, DATE_FORMAT.YEAR_MONTH_DAY);
      const endAt = getEndDate(
        selectedDate,
        therapist !== 'all' || isTherapist(permissions),
      );

      let _schedulePayload = {
        start_date: startAt,
        end_date: endAt,
        location_id: selectedLocation,
      };
      if (therapist !== 'all') {
        _schedulePayload.therapist_id = therapist;
      }
      setTherapistsLoading(false);

      customDataProvider
        .getList('therapistSchedule', { ..._schedulePayload })
        .then(schedule => {
          setScheduleData(schedule.data);

          if (!isTherapist(permissions)) {
            setResources(schedule, startAt);
          } else setTherapists(therapistsListData);

          const pagination = { page: 1, perPage: API_LIMIT };
          let _apptPayload = {
            start_at: startAt,
            end_at: endAt,
            pagination,
            location_id: selectedLocation,
          };
          let allAppointments = [];

          const fetchAppointments = (page = 1) => {
            const paginatedPayload = {
              ..._apptPayload,
              pagination: { page, perPage: API_LIMIT },
            };

            selectedLocation &&
              customDataProvider
                .getList('appointmentByDate', paginatedPayload)
                .then(result => {
                  allAppointments = removeDuplicateAppts([
                    ...allAppointments,
                    ...result.data,
                  ]);

                  const totalAppointments = result?.total || 0;
                  const nextOffset = page + API_LIMIT;

                  if (nextOffset < totalAppointments) {
                    fetchAppointments(nextOffset);
                  } else {
                    setAppointmentsLists(allAppointments);
                    setFetchData(false);
                    setClosePopup(false);
                  }
                });
          };
          fetchAppointments(0);
        })
        .catch(error => {
          setTherapists(therapistsListData);
        });
    }
  }, [therapistsListData, selectedDate, therapist, fetchData]);

  const debouncedSubmit = useDebouncedCallback(
    (info, successCallback, failureCallback) => {
      handleEvents(info, successCallback, failureCallback);
    },
    1000,
  );

  const handleUserUpdate = value => {
    const userId = appointment?.user?.id;
    const body = {
      id: userId,
      heard_about_us: value,
    };
    customDataProvider.update('user', body).then(({ data }) => {
      showToast('Heard about us updated successfully', 'success');
      customDataProvider.getOne('guest', { id: userId }).then(({ data }) => {
        setUser(data);
      });
    });
  };

  const handleUserUpdateWithDelay = useDebouncedCallback(
    value => handleUserUpdate(value),
    1500,
  );

  const getBackgroundColor = status => {
    switch (status) {
      case APPOINTMENT_STATUS.CONFIRMED:
        return '#66B2E4';
      case APPOINTMENT_STATUS.CHECK_IN:
        return '#f1c40f';
      case APPOINTMENT_STATUS.COMPLETE:
        return '#73CB42';
      // case APPOINTMENT_STATUS.CANCELLED:
      //   return 'purple';
      default:
        return '#66B2E4';
    }
  };
  const getBorderColor = status => {
    switch (status) {
      case APPOINTMENT_STATUS.CONFIRMED:
        return '#3498DB';
      case APPOINTMENT_STATUS.CHECK_IN:
        return '#f1c40f';
      case APPOINTMENT_STATUS.COMPLETE:
        return '#00B700';
      // case APPOINTMENT_STATUS.CANCELLED:
      //   return 'purple';
      default:
        return '#3498DB';
    }
  };

  const getSchedules = schedule => {
    let flatArray = [];
    let slArray = [];
    forIn(schedule, (value, key) => {
      value.forEach(v => flatArray.push({ ...v, start_day_key: key }));
      isEmpty(value) && slArray.push({ start_day_key: key });
    });
    let schedules = [];
    flatArray.forEach(sc => {
      sc.schedule.forEach(sched => {
        schedules.push({
          start: get(sched, 'start_time'),
          end: get(sched, 'end_time'),
          resourceId: sc.therapistId,
          rendering: 'background',
          display: 'inverse-background',
        });
      });
      sc?.blockedOutTimes?.forEach(a => {
        schedules.push({
          start: get(a, 'start_time_in_center'),
          end: get(a, 'end_time_in_center'),
          resourceId: sc.therapistId,
          title: a.notes
            ? `${a.block_out_time_type.name} - ${a.notes}`
            : `${a.block_out_time_type.name}`,
          color: '#3F5365',
        });
      });
      sc?.no_schedule?.forEach(a => {
        schedules.push({
          start: get(a, 'start_time'),
          end: get(a, 'end_time'),
          resourceId: sc.therapistId,
          rendering: 'background',
          backgroundColor: 'grey',
        });
      });
      if (isEmpty(sc.schedule)) {
        schedules.push({
          start: `${sc.start_day_key}T08:00:00`,
          end: `${sc.start_day_key}T22:00:00`,
          resourceId: sc.therapistId,
          rendering: 'background',
          backgroundColor: 'grey',
        });
      }
      if (!isEmpty(slArray)) {
        slArray?.forEach(sl => {
          schedules.push({
            start: `${sl.start_day_key}T08:00:00`,
            end: `${sl.start_day_key}T22:00:00`,
            resourceId: sc.therapistId,
            rendering: 'background',
            backgroundColor: 'grey',
          });
        });
      }
    });
    return schedules;
  };
  const getIcons = appt => {
    let icons = [];
    if (appt?.user?.has_membership) icons.push('star');
    if (appt?.user?.vi_squeeze) icons.push('emoji_events');
    if (appt?.user?.minor) icons.push('escalator_warning');
    if (
      appt?.user?.dob &&
      isDayBirthday(appt.user.dob, appt?.timezone, appt?.start_at)
    )
      icons.push('cake');
    if (appt?.user?.pregnant) icons.push('local_hospital');
    if (appt?.user?.first_time_user) icons.push('fiber_new');
    return icons.join(' ');
  };

  //  - ${a.service.title[0].toUpperCase() + a.service.title.slice(1)}
  const modifiedAppts = (appts, schedule = null) => {
    const scheduleEvents = getSchedules(schedule);
    let apptEvents = appts.map(a => ({
      ...a,
      resourceId: a.therapist?.id,
      title: isBreakroom(permissions)
        ? `${a?.user?.first_name}\n $ICON`
        : isTherapist(permissions)
        ? `${a?.user?.first_name} ${a?.user?.last_name?.charAt(0)}\n $ICON`
        : `${a?.user?.first_name} ${a?.user?.last_name}\n $ICON`,
      start: momentTimezone.utc(a.start_at).tz(a.timezone).format(dateFormat),
      end: momentTimezone.utc(a.end_at).tz(a.timezone).format(dateFormat),
      backgroundColor: !!a.no_show ? '#DF535F' : getBackgroundColor(a?.status),
      borderColor: !!a.no_show ? '#D41B2B' : getBorderColor(a?.status),
      textColor: a?.status === APPOINTMENT_STATUS.CHECK_IN ? 'black' : 'white',
      extendedProps: { ...a, eventType: 'appointment', icons: getIcons(a) },
    }));
    apptEvents = apptEvents.filter(
      a => a.status !== APPOINTMENT_STATUS.CANCELLED || !!a.no_show,
    );
    return [...apptEvents, ...scheduleEvents];
  };

  const modifiedTherapists = therapists => {
    if (isTherapist(permissions)) {
      const currentUser = JSON.parse(localStorage.getItem('user'));
      let _therapist =
        therapists && therapists.filter(t => t.email === currentUser.email);
      return (
        _therapist &&
        _therapist.map(t => ({
          ...t,
          title: t.first_name,
          eventBackgroundColor: '#fdfdfd',
        }))
      );
    } else if (therapist !== 'all') {
      let _therapist =
        therapists && therapists.filter(t => t.id === Number(therapist));
      return (
        _therapist &&
        _therapist.map(t => ({
          ...t,
          title: t.first_name,
          eventBackgroundColor: '#fdfdfd',
        }))
      );
    }
    return (
      therapists &&
      therapists.map(t => ({
        ...t,
        title: t.first_name,
        eventBackgroundColor: '#fdfdfd',
      }))
    );
  };

  if (typeof window !== 'undefined') {
    Modal.setAppElement('body');
  }

  // if (therapistsLoading) return <Loading />;
  // if (appointmentsError) return <Error />;
  // if (!therapists) return null;

  const handleEventClick = info => {
    const _appointment = info.event.extendedProps;
    if (_appointment?.eventType === 'appointment') {
      setAppointment(_appointment);
      setWaiver(!!_appointment?.user?.liability_waiver);
      setShowModal(true);
    }
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleAppointmentUpdate = (action = 'checkin') => {
    if (
      appointment &&
      appointment?.no_show === 0 &&
      appointment.status !== APPOINTMENT_STATUS.CANCELLED
    ) {
      setShowLoader(true);
      customDataProvider
        .update('appointment', { id: appointment?.id, action: action })
        .then(() => {
          setFetchData(true);
          handleCancel();
          setShowLoader(false);
        })
        .catch(error => {
          setFetchData(false);
          setShowLoader(false);
        });
    }
  };

  const handleSignWaiver = () => {
    const userId = appointment?.user?.id;
    const body = {
      id: userId,
      liability_waiver: true,
    };
    setShowLoader(true);
    customDataProvider
      .update('user', body)
      .then(() => {
        setWaiver(true);
        setShowLoader(false);
      })
      .catch(error => error && setShowLoader(false));
  };

  const setResources = (schedule, startDate) => {
    const therapistsList = JSON.parse(localStorage.getItem('therapistsList'));
    if (therapist === 'all') {
      const scData = schedule?.data[startDate];
      const filteredTherapistsList =
        therapistsList &&
        scData &&
        therapistsList.filter(th =>
          scData.find(sc => sc.therapistId === th.id),
        );
      const isSame = isEqual(filteredTherapistsList, therapists);
      if (!isSame && scData) {
        setTherapists(filteredTherapistsList);
      }
    } else {
      if (!isEqual(therapistsList, therapists)) {
        setTherapists(therapistsList);
      }
    }
  };

  const removeDuplicateAppts = appointments => {
    return Array.from(
      new Map(appointments.map(item => [item.id, item])).values(),
    );
  };

  const handleEvents = (info, successCallback, failureCallback) => {
    if (!showModal && scheduleData && !therapistsLoading && appoitnmetsLists) {
      successCallback(modifiedAppts(appoitnmetsLists, scheduleData));
    }
  };

  const iconWrap = (icon, text) => (
    <span className="legen-icon-wrapper">
      <div className="fc-title-icon legen-icon">{icon}</div>
      <div className="legen-name">{text}</div>
    </span>
  );

  const renderIconography = () => (
    <>
      {iconWrap('star_sharp', 'Member')}
      {iconWrap('cake', 'Birthday')}
      {iconWrap('escalator_warning', 'Minor')}
      {iconWrap('fiber_new_sharp', 'First Time')}
      {iconWrap('emoji_events_sharp', 'V.I. Squeeze')}
      {iconWrap('local_hospital', 'Pregnant')}
    </>
  );

  const handleTherapistFilter = therapistId => {
    if (therapistId !== undefined) {
      setIsAwaiting(true);
      setTherapist(therapistId);
      setGridView(
        therapistId === 'all' ? 'resourceTimeGridDay' : 'resourceTimeGridWeek',
      );
      setFetchData(true);
      setTimeout(() => {
        setIsAwaiting(false);
      }, 50);
    }
  };

  const gotoDate = date => {
    setSelectedDate(date);
    let calendarApi = getApi();
    calendarApi.gotoDate(date);
    localStorage.setItem('selectedDate', calendarApi.getDate());
    setFetchData(true);
  };

  const getApi = () => {
    const { current: calendarDom } = calendarRef;
    return calendarDom ? calendarDom.getApi() : null;
  };

  const resetModal = () => {
    if (!closePopup) {
      setShowModal(false);
      let str = window.location.href;
      if (str.indexOf('/1') > -1) {
        str = str.substring(0, str.length - 2);
        window.location.replace(str);
      }
    }
  };

  const handleSave = form => {
    setSelectedLocation(form?.location_id);
    localStorage.setItem('selectedLocation', form?.location_id);
    setShowLocationSelectorModal(false);
  };

  const selectLocationModal = () => {
    const header = 'Select Location';
    return (
      <Modal isOpen={showLocationSelectorModal}>
        <div className="modal-header">
          <div className="light-text">{header}</div>
        </div>
        <SimpleForm save={handleSave}>
          <SelectInput
            source="location_id"
            optionText="name"
            choices={getLocations(workLocations)}
            label="Choose Location"
            style={{ width: '100%' }}
            validate={validateRequired}
          />
        </SimpleForm>
      </Modal>
    );
  };

  if (showLoader || therapistsLoading) return <Loading />;
  const render = () => {
    const allTherapistsList = JSON.parse(
      localStorage.getItem('therapistsList'),
    );

    return (
      <>
        {!selectedLocation && selectLocationModal()}
        {
          <Modal isOpen={showModal} className="scroll-within w550px">
            <div className="modal-header">
              <div className="light-text">Appointment Details</div>
              <CloseIcon
                onClick={() => resetModal()}
                className={`close-button ${closePopup ? 'disabled' : ''}`}
                style={
                  closePopup ? { cursor: 'not-allowed', opacity: 0.5 } : {}
                }
              />
            </div>
            <TabbedShowLayout className="max60vh">
              <Tab label="Details">
                <div className="modal-body">
                  <div className="details-display grid4060 list">
                    <div className="font-bold">Guest</div>
                    {
                      <div className="break-word">
                        {isBreakroom(permissions) ? (
                          <div>{appointment?.user?.first_name}</div>
                        ) : isTherapist(permissions) ? (
                          <div>
                            {appointment?.user?.first_name}{' '}
                            {appointment?.user?.last_name?.charAt(0)}
                          </div>
                        ) : (
                          <>
                            <Link
                              className="underline"
                              to={`/guest/${appointment?.user_id}`}
                            >
                              {appointment?.user?.first_name}{' '}
                              {appointment?.user?.last_name}
                            </Link>
                            <br />
                          </>
                        )}
                        {getIcons(appointment) && (
                          <div className="fc-title-icon ml-2">
                            {getIcons(appointment)}
                          </div>
                        )}
                      </div>
                    }
                    {!isTherapist(permissions) && !isBreakroom(permissions) && (
                      <>
                        <div className="font-bold">Guest Phone</div>
                        <div className="break-word">
                          {formatPhoneNumber(appointment?.user?.phone)}
                        </div>
                      </>
                    )}
                    {!isTherapist(permissions) && (
                      <>
                        <div className="font-bold">Guest Email</div>
                        <div className="break-word">
                          {appointment?.user?.email}
                        </div>
                      </>
                    )}
                    <div className="font-bold">Date/Time</div>
                    {
                      <div className="break-word">
                        {' '}
                        {isBreakroom(permissions) ? (
                          <span>
                            {getAppointmentDateTime(
                              appointment?.start_at,
                              appointment?.end_at,
                              appointment?.timezone,
                            )}
                          </span>
                        ) : (
                          <Link
                            to={`/guest/appointment/${appointment?.id}/view-guest-details/${appointment?.user_id}`}
                            className="underline"
                          >
                            {getAppointmentDateTime(
                              appointment?.start_at,
                              appointment?.end_at,
                              appointment?.timezone,
                            )}
                          </Link>
                        )}
                      </div>
                    }
                    <div className="font-bold">Service</div>
                    <div className="break-word">
                      {appointment?.service?.label}
                    </div>
                    <div className="font-bold">Therapist</div>
                    <div className="break-word">
                      {' '}
                      {isBreakroom(permissions) ||
                      isGss(permissions) ||
                      isTherapist(permissions) ? (
                        <span>
                          {appointment?.therapist?.first_name}{' '}
                          {appointment?.therapist?.last_name}
                        </span>
                      ) : (
                        <Link
                          to={`/employee/edit/${appointment?.therapist?.id}`}
                          className="underline"
                        >
                          {appointment?.therapist?.first_name}{' '}
                          {appointment?.therapist?.last_name}
                        </Link>
                      )}
                    </div>
                    <div className="font-bold">Minor?</div>
                    <div>{appointment?.user?.minor ? 'Yes' : 'No'}</div>
                    {!isTherapist(permissions) && (
                      <>
                        <div className="font-bold">Flexible?</div>
                        <div>
                          {appointment?.similar_therapist
                            ? `I'm ok with similar therapist`
                            : 'Must be the same therapist'}
                        </div>

                        <div className="font-bold">Guest Notes</div>
                        <div className="break-word">
                          <DebounceInput
                            className="text-area-border"
                            element="textarea"
                            minLength={2}
                            debounceTimeout={1000}
                            value={guestNotes}
                            onChange={event => {
                              setGuestNotes(event.target.value);
                              setClosePopup(true);
                            }}
                          />
                        </div>
                        <div className="font-bold">Appointment Notes</div>
                        <div>
                          <DebounceInput
                            className="text-area-border"
                            minLength={2}
                            element="textarea"
                            debounceTimeout={1000}
                            value={apptNotes}
                            onChange={event => {
                              setApptNotes(event.target.value);
                              setClosePopup(true);
                            }}
                          />
                        </div>
                        {!(
                          user?.heard_about_us ||
                          appointment?.user?.heard_about_us
                        ) && (
                          <>
                            <div className="font-bold danger-color">
                              Heard About Us?
                            </div>
                            <div>
                              <select
                                className="border-customized-input field-border mli-0"
                                onChange={e =>
                                  e.target.value !== 'Other'
                                    ? handleUserUpdate(e.target.value)
                                    : setheardAboutUs(e.target.value)
                                }
                                defaultValue="NULL"
                                required
                              >
                                <option disabled>NULL</option>
                                {HEARD_ABOUT_US.map(s => (
                                  <option key={s.id}>{s.name}</option>
                                ))}
                              </select>
                            </div>
                            {heardAboutUs === 'Other' && (
                              <>
                                <div className="font-bold">Please Specify</div>
                                <div className="break-word">
                                  <input
                                    className="border-customized-input field-border mli-0"
                                    source="heard_other"
                                    onChange={event =>
                                      handleUserUpdateWithDelay(
                                        event.target.value,
                                      )
                                    }
                                  />
                                </div>
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                  <div className="mt-6 text-right">
                    <hr className="mb-6" />
                    {!isTherapist(permissions) &&
                      !isBreakroom(permissions) &&
                      appointment?.status === APPOINTMENT_STATUS.CHECK_IN &&
                      (waiver || !!appointment?.user?.liability_waiver) && (
                        <button
                          disabled={!user?.heard_about_us}
                          className="button-blue mr-6"
                          onClick={() => handleAppointmentUpdate('uncheck-in')}
                        >
                          Uncheck In
                        </button>
                      )}
                    {!isTherapist(permissions) &&
                      !isBreakroom(permissions) &&
                      appointment?.status === APPOINTMENT_STATUS.CONFIRMED &&
                      (waiver || !!appointment?.user?.liability_waiver) && (
                        <button
                          disabled={!user?.heard_about_us}
                          className="button-blue mr-6"
                          onClick={() => handleAppointmentUpdate()}
                        >
                          Check In
                        </button>
                      )}
                    {!isTherapist(permissions) &&
                      !isBreakroom(permissions) &&
                      !waiver && (
                        <button
                          className="button-blue mr-6"
                          onClick={() => handleSignWaiver()}
                        >
                          Sign Waiver
                        </button>
                      )}
                  </div>
                </div>
              </Tab>
              {!(isTherapist(permissions) || isBreakroom(permissions)) &&
                appointment?.status !== APPOINTMENT_STATUS.COMPLETE &&
                // appointment?.status !== APPOINTMENT_STATUS.CHECK_IN &&
                !appointment?.no_show && (
                  <Tab label="Edit">
                    <div className="modal-body">
                      <AppointmentEdit
                        props
                        appointment={appointment}
                        callBack={handleCancel}
                        therapists={therapists}
                        permissions={permissions}
                        setShowLoader={setShowLoader}
                        selectedLocation={selectedLocation}
                        setFetchData={setFetchData}
                      />
                    </div>
                  </Tab>
                )}
            </TabbedShowLayout>
          </Modal>
        }
        <div className="details-display-wrap mt-2">
          <div className="details-display flex-mode one z-10">
            <div className="details-field">
              <label>Choose Date:</label>
              <DatePicker
                className="field-border w-150"
                selected={selectedDate}
                onChange={date => gotoDate(date)}
                onFocus={e => (e.target.readOnly = true)}
              />
            </div>
            {workLocations && (
              <div className="details-field">
                <label for="therapist">Locations:</label>
                <select
                  name="location"
                  id="location"
                  className="field-border w-150"
                  onChange={e => {
                    setSelectedLocation(e.target.value);
                    localStorage.setItem(
                      'recentSelectedLocation',
                      e.target.value,
                    );
                  }}
                  defaultValue={selectedLocation}
                >
                  {workLocations &&
                    workLocations.map(t => (
                      <option value={t.location_id}>{t.location.name}</option>
                    ))}
                </select>
              </div>
            )}
            {!isTherapist(permissions) && (
              <div className="details-field">
                <label for="therapist">Therapist:</label>
                <select
                  name="therapist"
                  id="therapist"
                  className="field-border w-150"
                  onChange={e => handleTherapistFilter(e.target.value)}
                  value={therapist}
                >
                  <option value="all">All</option>
                  {allTherapistsList &&
                    allTherapistsList.map(t => (
                      <option value={t.id}>{t.first_name}</option>
                    ))}
                </select>
              </div>
            )}
          </div>
          <div className="text-right legen-wrapper">{renderIconography()}</div>
        </div>
        {!isAwaiting &&
          selectedLocation &&
          therapists &&
          !therapistsLoading && (
            <FullCalendar
              schedulerLicenseKey="0617878319-fcs-1641081347"
              customButtons={{
                next: {
                  click: function () {
                    calendarRef.current.calendar.next();

                    let calendarApi = getApi();
                    localStorage.setItem('selectedDate', calendarApi.getDate());
                    setSelectedDate(calendarApi.getDate());
                    setFetchData(true);
                  },
                },
                prev: {
                  click: function () {
                    calendarRef.current.calendar.prev();

                    let calendarApi = getApi();
                    localStorage.setItem('selectedDate', calendarApi.getDate());
                    setSelectedDate(calendarApi.getDate());
                    setFetchData(true);
                  },
                },
                today: {
                  text: 'today',
                  click: function () {
                    calendarRef.current.calendar.today();
                    let calendarApi = getApi();
                    localStorage.setItem('selectedDate', calendarApi.getDate());
                    setSelectedDate(calendarApi.getDate());
                    setFetchData(true);
                  },
                },
              }}
              header={{
                right: 'today, prev, next',
                center: 'title',
                left: 'resourceDayGridMonth',
              }}
              views={{
                day: {
                  titleFormat: {
                    weekday: 'long',
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric',
                  },
                },
              }}
              allDaySlot={false}
              slotDuration="00:30:00"
              minTime="08:00:00"
              maxTime="22:00:00"
              // events={modifiedAppts(appointments)}
              events={(info, successCallback, failureCallback) =>
                !showModal &&
                debouncedSubmit(info, successCallback, failureCallback)
              }
              resources={modifiedTherapists(therapists)}
              resourceOrder="first_name"
              plugins={[
                resourceTimeGridPlugin,
                dayGridPlugin,
                interactionPlugin,
                timeGridPlugin,
              ]}
              defaultView={
                isTherapist(permissions) ? 'resourceTimeGridWeek' : gridView
              }
              editable={true}
              selectable={true}
              selectMirror={true}
              draggable={false}
              droppable={false}
              eventStartEditable={false}
              disableResizing={true}
              eventDurationEditable={false}
              dragScroll={true}
              dayMaxEvents={true}
              weekends={true}
              firstDay={1}
              eventClick={handleEventClick}
              defaultDate={selectedDate}
              height={'auto'}
              ref={calendarRef}
              // viewRender={handleDayClick()}
              eventRender={info => {
                // info.event.extendedProps.img;
                info.el.innerHTML = info.el.innerHTML.replace(
                  '$ICON',
                  "<div class='fc-title-icon'>" +
                    info.event.extendedProps.icons +
                    '</div>',
                );
              }}
              evenResize={info => {
                info.event.classNames = 'fc-event';
              }}
              // dayRender={date => {
              //   date.el.className = 'cal-background';
              // }}
            />
          )}
      </>
    );
  };

  return render();
};
