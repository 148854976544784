import { showToast } from '../utils';
import { constructUrl } from '../utils/api';

const { client } = require('../services/api-client');

const getAppointmentList = async (_resource, params) => {
  try {
    let { filters, start_at, end_at, therapist_id, pagination } = params;

    const url = constructUrl('appointments', {
      filters,
      pagination,
    });

    const finalUrl = start_at
      ? therapist_id
        ? `${url}&start_at=${start_at}&end_at=${end_at}&therapist_id=${therapist_id}`
        : `${url}&start_at=${start_at}&end_at=${end_at}`
      : url;

    const resp = await client(finalUrl, { authNeeded: true });
    const _appointmentsData = {
      data: resp.items,
      total: resp.count,
    };
    localStorage.setItem('appointmentsList', JSON.stringify(_appointmentsData));
    return _appointmentsData;
  } catch (err) {
    showToast(err.message, 'error');
    return Promise.reject(err);
  }
};
const getAppointmenByDatetList = async (_resource, params) => {
  try {
    let {
      filters,
      start_at,
      end_at,
      therapist_id,
      pagination,
      location_id,
    } = params;

    const url = constructUrl('appointmentsByDate', {
      filters,
      pagination,
      isOffetLimit: true,
    });

    const finalUrl = start_at
      ? therapist_id
        ? `${url}&start_at=${start_at}&end_at=${end_at}&location_id=${location_id}&therapist_id=${therapist_id}`
        : `${url}&start_at=${start_at}&end_at=${end_at}&location_id=${location_id}`
      : url;

    const resp = await client(finalUrl, { authNeeded: true });
    const _appointmentsData = {
      data: resp.items,
      total: resp.count,
    };
    return _appointmentsData;
  } catch (err) {
    showToast('Error in getList - Appointment', 'error');
    return Promise.reject(err);
  }
};

const getAppointment = async (_resource, params) => {
  try {
    const { id } = params;
    const url = constructUrl(`appointments/${id}`, {});
    const resp = await client(url, { authNeeded: true });
    return {
      data: resp.appointment,
    };
  } catch (err) {
    showToast(err.message, 'error');
    return Promise.reject(err);
  }
};

const getAppointmentTherapistNotes = async (_resource, params) => {
  try {
    const { userId } = params;
    const _sort = encodeURIComponent(
      `user_id=${userId}&sortField=created_at&sortOrder=desc`,
    );
    const url = constructUrl(
      `appointment/notes?fields=user.*&filters=${_sort}`,
      {
        pagination: false,
      },
    );
    const resp = await client(url, { authNeeded: true });
    return {
      data: resp.items,
    };
  } catch (err) {
    showToast(err.message, 'error');
    return Promise.reject(err);
  }
};

const updateAppointment = async (_resource, params) => {
  try {
    const { id, action, payload } = params;
    let url;
    if (action === 'checkin') {
      url = constructUrl(`appointment/${id}/checkin`, { pagination: false });
    } else if (action === 'edit') {
      url = constructUrl(`appointment/${id}`, { pagination: false });
    } else if (action === 'noshow') {
      url = constructUrl(`appointment/${id}/noshow`, { pagination: false });
    }
    else if(action === 'uncheck-in') {
      url = constructUrl(`appointment/${id}/undo-checkin`, { pagination: false });
    }
    const resp = await client(url, {
      authNeeded: true,
      method: 'PUT',
      data: payload,
    });
    return {
      data: resp,
    };
  } catch (err) {
    showToast(err.message, 'error');
    return Promise.reject(err);
  }
};

const appointmentRefund = async (_resource, params) => {
  try {
    const { payload, id } = params;
    const url = constructUrl(`appointment/${id}/refund`, { pagination: false });
    const resp = await client(url, {
      authNeeded: true,
      method: 'PUT',
      data: payload,
    });
    return {
      data: resp,
    };
  } catch (err) {
    showToast(err.message, 'error');
    return Promise.reject(err);
  }
};

export {
  getAppointmentList,
  getAppointmenByDatetList,
  getAppointment,
  updateAppointment,
  getAppointmentTherapistNotes,
  appointmentRefund,
};
