/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  DateInput,
  NumberInput,
  SaveButton,
  SearchInput,
  SelectInput,
  SimpleForm,
  Tab,
  TabbedShowLayout,
  TextInput,
  Toolbar,
  useNotify,
} from 'react-admin';
import '../appointment/appointment.css';
import '../user/user.css';
import {
  getFiltersDate,
  getLocations,
  isAdmin,
  isGss,
  isOperatingPartner,
  isShopManager,
  isSuperAdmin,
} from '../utils';
import CancelMembershipReport from './cancelMembership-report';
import DistributionReport from './distribution-report';
import GiftsReport from './gifts-report';
import HallSqueezersReport from './hall-squeezers-report';
import RatingsReport from './ratings-report';
import ScoreCardReport from './scorecard-report';
import TipsReport from './tips-report';
import { customDataProvider } from '../data-provider';
import CrossLocationAdjustmentReport from './crossLocationAdjustment-report';
import MembersReport from './members-report';
import { MEMBERS_REPORT_STATUS } from '../utils/constants';
import PromosReport from './promos-report';
import DemosReport from './demos-report';
import SearchIcon from '@material-ui/icons/Search';
import ExportIcon from '@material-ui/icons/CloudDownload';
import ClearIcon from '@material-ui/icons/Clear';
import PaymentReport from './payment-report';

export const ReportsList = ({ permissions, ...props }) => {
  const selectedLocation = localStorage.getItem('selectedLocation');
  const filters = selectedLocation ? { location_id: selectedLocation } : '';
  const [ratingFilter, setRatingFilter] = useState(getFiltersDate('ratings'));
  const [tipsFilter, setTipsFilter] = useState(getFiltersDate('tips'));
  const [hallFilter, setHallFilter] = useState({});
  const [cancelMemFilter, setCancelMemFilterFilter] = useState(
    getFiltersDate('cancelMember'),
  );
  const [giftFilter, setGiftFilter] = useState(getFiltersDate('gifts'));
  // const [promoFilter, setPromoFilter] = useState(getFiltersDate('promo'));
  const [scoreCardFilter, setScoreCardFilter] = useState(
    getFiltersDate('scoreCard'),
  );
  const [distributionFilter, setDistributionFilter] = useState(
    getFiltersDate('distribution'),
  );
  const [crossLocationAdjustmentFilter, setCrossLocationAdjustmentFilter] =
    useState(getFiltersDate('crossLocationAdjustment'));
  const [membersFilter, setMembersFilter] = useState({
    ...getFiltersDate('members'),
    status: MEMBERS_REPORT_STATUS[0].id,
    clear: false,
  });
  const [promosFilter, setPromosFilter] = useState({ code: '' });
  const [ demosFilter, setDemosFilter ] = useState( { ...filters, clear: false } );
    const [paymentsFilter, setPaymentsFilter] = useState(
    getFiltersDate('payments'),
  );

  const [workLocations, setWorkLocations] = useState(null);

  const notify = useNotify();

  useEffect(() => {
    customDataProvider.getList('userlocations').then(data => {
      setWorkLocations(data.data);
    });
  }, []);

  const exportMembersReport = form => {
    customDataProvider
      .getList('export-members-report', {
        filters: { ...form, csv: true },
      })
      .then(data => {
        if (data.csv) {
          const message =
            data?.message ||
            `You’ll receive the exported file via email shortly`;
          notify(message, 'info');
        }
      });
  };

  const exportPromosReport = form => {
    customDataProvider
      .getList('export-promos-report', {
        filters: { ...form, csv: true },
      })
      .then(data => {
        if (data.csv) {
          const message =
            data?.message ||
            `You’ll receive the exported file via email shortly`;
          notify(message, 'info');
        }
      });
  };

  const exportDemosReport = form => {
    customDataProvider
      .getList('export-demos-report', {
        filters: { ...form, csv: true },
      })
      .then(data => {
        if (data.csv) {
          const message =
            data?.message ||
            `You’ll receive the exported file via email shortly`;
          notify(message, 'info');
        }
      });
  };

  const exportPaymentsReport = form => {
    customDataProvider
      .getList('export-payments-report', {
        filters: { ...form, csv: true },
      })
      .then(data => {
        if (data.csv) {
          const message =
            data?.message ||
            `You’ll receive the exported file via email shortly`;
          notify(message, 'info');
        }
      });
  };

  const clear = () => {
    setRatingFilter({ ...getFiltersDate('ratings') });
    setTipsFilter({ ...getFiltersDate('tips') });
    setHallFilter({});
    setCancelMemFilterFilter({ ...getFiltersDate('cancelMember') });
    setGiftFilter({ ...getFiltersDate('gifts') });
    // setPromoFilter({});
    setScoreCardFilter({ ...getFiltersDate('scoreCard') });
    setDistributionFilter({ ...getFiltersDate('distribution') });
    setCrossLocationAdjustmentFilter({
      ...getFiltersDate('crossLocationAdjustment'),
    });
    setMembersFilter({
      ...getFiltersDate('members'),
      status: MEMBERS_REPORT_STATUS[0].id,
      clear: true,
    });
    setPromosFilter({ code: '' });
    setDemosFilter( { ...filters, clear: true } );
    setPaymentsFilter({ ...getFiltersDate('payments') });

  };

  const SearchToolBar = props =>
  {
    const {showExport, showSearch=true,reportName  } = props;
    return (
      <Toolbar {...props}>
       {showSearch &&  <SaveButton label="Search" icon={<SearchIcon />} />}
        {showExport && reportName && (
          <SaveButton
            label="Export"
            onSave={reportName}
            icon={<ExportIcon />}
          />
        )}
        <SaveButton label="Clear" onSave={clear} icon={<ClearIcon />} />
      </Toolbar>
    );
  };

  // rating search
  const handleRatingChange = form => {
    const { name, dateLower, dateUpper, location_id } = form;
    const filters = {
      name: name,
      dateLower: dateLower,
      dateUpper: dateUpper,
      location_id: location_id,
    };
    setRatingFilter({ ...filters });
  };

  const RatingFilters = () => {
    return (
      <SimpleForm
        className="styled-form2 filters-form"
        toolbar={<SearchToolBar />}
        initialValues={{
          name: ratingFilter.name,
          dateLower: ratingFilter.dateLower,
          dateUpper: ratingFilter.dateUpper,
          location_id: ratingFilter.location_id || filters.location_id,
        }}
        save={handleRatingChange}
      >
        <div className="filters-flex">
          <TextInput source="name" label="Name" />
          <DateInput source="dateLower" label="Rating Date >=" />
          <DateInput source="dateUpper" label="Rating Date <=" />
          {workLocations && (
            <SelectInput
              style={{ width: '15rem' }}
              source="location_id"
              optionText="name"
              choices={getLocations(workLocations)}
              label="Choose Location"
            />
          )}
        </div>
      </SimpleForm>
    );
  };

  // Tips Search
  const handleTipsChange = form => {
    const { name, dateUpper, dateLower, location_id } = form;
    const filters = {
      name: name,
      dateLower: dateLower,
      dateUpper: dateUpper,
      location_id: location_id,
    };
    setTipsFilter({ ...filters });
  };
  const TipsFilters = () => {
    return (
      <SimpleForm
        className="styled-form2 filters-form"
        toolbar={<SearchToolBar />}
        initialValues={{
          name: tipsFilter.name,
          dateLower: tipsFilter.dateLower,
          dateUpper: tipsFilter.dateUpper,
          location_id: tipsFilter.location_id || filters.location_id,
        }}
        save={handleTipsChange}
      >
        <div className="filters-flex">
          <TextInput source="name" label="Name" />
          <DateInput source="dateLower" label="Tip Date >=" />
          <DateInput source="dateUpper" label="Tip Date <=" />
          {workLocations && (
            <SelectInput
              style={{ width: '15rem' }}
              source="location_id"
              optionText="name"
              choices={getLocations(workLocations)}
              label="Choose Location"
            />
          )}
        </div>
      </SimpleForm>
    );
  };

  //Hall squeezers Filter
  const handleHallChange = form => {
    const { name, countUpper, countLower, location_id } = form;
    const filters = {
      name: name,
      countLower: countLower,
      countUpper: countUpper,
      location_id: location_id,
    };
    setHallFilter({ ...filters });
  };
  const HallSqueezerFilters = () => {
    return (
      <SimpleForm
        className="styled-form2 filters-form"
        toolbar={<SearchToolBar />}
        initialValues={{
          name: hallFilter.name,
          countLower: hallFilter.countLower,
          countUpper: hallFilter.countUpper,
          location_id: hallFilter.location_id || filters.location_id,
        }}
        save={handleHallChange}
      >
        <div className="filters-flex">
          <TextInput source="name" label="Name" />
          <NumberInput source="countLower" label="Completed Appointments >=" />
          <NumberInput source="countUpper" label="Completed Ammointments <=" />
          {workLocations && (
            <SelectInput
              style={{ width: '15rem' }}
              source="location_id"
              optionText="name"
              choices={getLocations(workLocations)}
              label="Choose Location"
            />
          )}
        </div>
      </SimpleForm>
    );
  };

  //CancelledMembers Filter
  const handleCancelMembersChange = form => {
    const { dateUpper, dateLower, location_id } = form;
    const filters = {
      dateLower: dateLower,
      dateUpper: dateUpper,
      // location_id: location_id,
    };
    setCancelMemFilterFilter({ ...filters });
  };
  const CancelledMembersFilters = () => {
    return (
      <SimpleForm
        className="styled-form2 filters-form"
        toolbar={<SearchToolBar />}
        initialValues={{
          dateLower: cancelMemFilter.dateLower,
          dateUpper: cancelMemFilter.dateUpper,
        }}
        save={handleCancelMembersChange}
      >
        <div className="filters-flex">
          <DateInput source="dateLower" label="Cancellation Date >=" />
          <DateInput source="dateUpper" label="Cancellation Date <=" />
        </div>
      </SimpleForm>
    );
  };

  //Gift search
  const handleGiftReportChange = form => {
    const {
      purchaseDateLower,
      purchaseDateUpper,
      redeemedAtLower,
      redeemedAtUpper,
      location_id,
    } = form;
    const filters = {
      purchaseDateLower: purchaseDateLower,
      purchaseDateUpper: purchaseDateUpper,
      redeemedAtLower: redeemedAtLower,
      redeemedAtUpper: redeemedAtUpper,
      location_id: location_id,
    };
    setGiftFilter({ ...filters });
  };

  const GiftFilters = () => {
    return (
      <SimpleForm
        className="styled-form2 filters-form"
        toolbar={<SearchToolBar />}
        initialValues={{
          purchaseDateLower: giftFilter.purchaseDateLower,
          purchaseDateUpper: giftFilter.purchaseDateUpper,
          redeemedAtLower: giftFilter.redeemedAtLower,
          redeemedAtUpper: giftFilter.redeemedAtUpper,
          location_id: giftFilter.location_id || filters.location_id,
        }}
        save={handleGiftReportChange}
      >
        <div className="filters-flex">
          <DateInput source="purchaseDateLower" label="Gift Purchase Date >=" />
          <DateInput source="purchaseDateUpper" label="Gift Purchase Date <=" />
          <DateInput source="redeemedAtLower" label="RedeemedAt Date >=" />
          <DateInput source="redeemedAtUpper" label="RedeemedAt Date <=" />
          {workLocations && (
            <SelectInput
              style={{ width: '15rem' }}
              source="location_id"
              optionText="name"
              choices={[
                { id: 'all', name: 'All' },
                ...getLocations(workLocations),
              ]}
              label="Choose Location"
            />
          )}
        </div>
      </SimpleForm>
    );
  };

  // Promo Search
  // const handlePromoChange = form => {
  //   const { txnDateLower, txnDateUpper, location_id } = form;
  //   const filters = {
  //     txnDateLower: txnDateLower,
  //     txnDateUpper: txnDateUpper,
  //     location_id: location_id,
  //   };
  //   setPromoFilter({ ...filters });
  // };

  // const PromoFilters = () => {
  //   return (
  //     <SimpleForm
  //       className="styled-form2 filters-form"
  //       toolbar={<SearchToolBar />}
  //       initialValues={{
  //         txnDateLower: promoFilter.txnDateLower,
  //         txnDateUpper: promoFilter.txnDateUpper,
  //         location_id: promoFilter.location_id || filters.location_id,
  //       }}
  //       save={handlePromoChange}
  //     >
  //       <div className="filters-flex">
  //         <DateInput source="txnDateLower" label="Promo Date >=" />
  //         <DateInput source="txnDateUpper" label="Promo Date <=" />
  //         {workLocations && (
  //           <SelectInput
  //             source="location_id"
  //             optionText="name"
  //             choices={getLocations(workLocations)}
  //             label="Choose Location"
  //           />
  //         )}
  //       </div>
  //     </SimpleForm>
  //   );
  // };

  // ScoreCard Search
  const handleScoreCardChange = form => {
    const { dateUpper, dateLower, location_id } = form;
    const filters = {
      dateLower: dateLower,
      dateUpper: dateUpper,
      location_id: location_id,
    };
    setScoreCardFilter({ ...filters });
  };

  const ScoreCardFilters = () => {
    return (
      <SimpleForm
        className="styled-form2 filters-form"
        toolbar={<SearchToolBar />}
        initialValues={{
          dateLower: scoreCardFilter.dateLower,
          dateUpper: scoreCardFilter.dateUpper,
          location_id: scoreCardFilter.location_id || filters.location_id,
        }}
        save={handleScoreCardChange}
      >
        <div className="filters-flex">
          <DateInput source="dateLower" label="Date >=" />
          <DateInput source="dateUpper" label="Date <=" />
          {workLocations && (
            <SelectInput
              style={{ width: '15rem' }}
              source="location_id"
              optionText="name"
              choices={getLocations(workLocations)}
              label="Choose Location"
            />
          )}
        </div>
      </SimpleForm>
    );
  };

  // Distribution report
  const handleDistributionChange = form => {
    const { dateUpper, dateLower, location_id } = form;
    const filters = {
      dateLower: dateLower,
      dateUpper: dateUpper,
      location_id: location_id,
    };
    setDistributionFilter({ ...filters });
  };

  const DistributionFilters = () => {
    return (
      <SimpleForm
        className="styled-form2 filters-form"
        toolbar={<SearchToolBar />}
        initialValues={{
          dateLower: distributionFilter.dateLower,
          dateUpper: distributionFilter.dateUpper,
          location_id: distributionFilter.location_id || filters.location_id,
        }}
        save={handleDistributionChange}
      >
        <div className="filters-flex">
          <DateInput source="dateLower" label="Date >=" />
          <DateInput source="dateUpper" label="Date <=" />
          {workLocations && (
            <SelectInput
              style={{ width: '15rem' }}
              source="location_id"
              optionText="name"
              choices={getLocations(workLocations)}
              label="Choose Location"
            />
          )}
        </div>
      </SimpleForm>
    );
  };

  //cross location
  const handleCrossLocationAdjustmentChange = form => {
    const { dateUpper, dateLower, location_id } = form;
    const filters = {
      dateLower: dateLower,
      dateUpper: dateUpper,
      location_id: location_id,
    };
    setCrossLocationAdjustmentFilter({ ...filters });
  };

  const CrossLocationAdjustmentFilterFilters = () => {
    return (
      <SimpleForm
        className="styled-form2 filters-form"
        toolbar={<SearchToolBar />}
        initialValues={{
          dateLower: crossLocationAdjustmentFilter.dateLower,
          dateUpper: crossLocationAdjustmentFilter.dateUpper,
          location_id:
            crossLocationAdjustmentFilter.location_id || filters.location_id,
        }}
        save={handleCrossLocationAdjustmentChange}
      >
        <div className="filters-flex">
          <DateInput source="dateLower" label="Date >=" />
          <DateInput source="dateUpper" label="Date <=" />
          {workLocations && (
            <SelectInput
              style={{ width: '15rem' }}
              source="location_id"
              optionText="name"
              choices={getLocations(workLocations)}
              label="Choose Location"
            />
          )}
        </div>
      </SimpleForm>
    );
  };

  //Members
  const handleMembersChange = form => {
    const { dateUpper, dateLower, location_id, status } = form;
    const filters = {
      dateLower: dateLower,
      dateUpper: dateUpper,
      location_id: location_id,
      status: status,
      clear: false,
    };
    setMembersFilter({ ...filters });
  };

  const MembersFilters = () => {
    return (
      <SimpleForm
        className="styled-form2 filters-form"
        toolbar={
          <SearchToolBar showExport={true} reportName={exportMembersReport} />
        }
        initialValues={{
          dateLower: membersFilter.dateLower,
          dateUpper: membersFilter.dateUpper,
          location_id: membersFilter.location_id || filters.location_id,
          status: membersFilter.status,
        }}
        save={handleMembersChange}
      >
        <div className="filters-flex">
          <DateInput source="dateLower" label="Started Date >=" />
          <DateInput source="dateUpper" label="Started Date <=" />
          {workLocations && (
            <SelectInput
              style={{ width: '15rem' }}
              source="location_id"
              optionText="name"
              choices={getLocations(workLocations)}
              label="Choose Location"
            />
          )}
          <SelectInput
            style={{ width: '15rem' }}
            source="status"
            optionText="name"
            choices={MEMBERS_REPORT_STATUS}
            label="Choose Status"
          />
        </div>
      </SimpleForm>
    );
  };

  const handlePromosChange = form => {
    const { code } = form;
    const filters = {
      // location_id: location_id,
      code: code,
    };
    setPromosFilter({ ...filters });
  };

  const PromosFilters = () => {
    return (
      <SimpleForm
        className="styled-form2 filters-form"
        toolbar={
          <SearchToolBar showExport={true} reportName={exportPromosReport} />
        }
        initialValues={{
          // location_id: promosFilter.location_id || filters.location_id,
          code: promosFilter.code,
        }}
        save={handlePromosChange}
      >
        <div className="filters-flex">
          {/* {workLocations && (
            <SelectInput
              style={{ width: '15rem' }}
              source="location_id"
              optionText="name"
              choices={getLocations(workLocations)}
              label="Choose Location"
            />
          )} */}
          <SearchInput source="code" placeholder="Enter promo code" alwaysOn />
        </div>
      </SimpleForm>
    );
  };

  const handleDemosChange = form => {
    const { location_id } = form;
    const filters = {
      location_id,
      clear: false,
    };
    setDemosFilter({ ...filters });
  };

  const DemosFilters = () => {
    return (
      <SimpleForm
        className="styled-form2 filters-form"
        toolbar={
          <SearchToolBar showExport={true} reportName={exportDemosReport} />
        }
        initialValues={{
          location_id: demosFilter.location_id || filters.location_id,
        }}
        save={handleDemosChange}
      >
        <div className="filters-flex">
          {workLocations && (
            <SelectInput
              style={{ width: '15rem' }}
              source="location_id"
              optionText="name"
              choices={getLocations(workLocations)}
              label="Choose Location"
            />
          )}
        </div>
      </SimpleForm>
    );
  };

    const handlePaymentReportChange = form => {
    const { dateUpper, dateLower, location_id } = form;
    const filters = {
      dateLower: dateLower,
      dateUpper: dateUpper,
      location_id: location_id,
    };
    setPaymentsFilter({ ...filters });
  };

  const PaymentReportsFilters = () => {
    return (
      <SimpleForm
        className="styled-form2 filters-form"
        toolbar={ <SearchToolBar showExport={true} showSearch={false} reportName={exportPaymentsReport} />}
        initialValues={{
          dateLower: paymentsFilter.dateLower,
          dateUpper: paymentsFilter.dateUpper,
          location_id: paymentsFilter.location_id || filters.location_id,
        }}
        save={handlePaymentReportChange}
      >
        <div className="filters-flex">
          <DateInput source="dateLower" label="Date >=" />
          <DateInput source="dateUpper" label="Date <=" />
          {workLocations && (
            <SelectInput
              style={{ width: '15rem' }}
              source="location_id"
              optionText="name"
              choices={getLocations(workLocations)}
              label="Choose Location"
            />
          )}
        </div>
      </SimpleForm>
    );
  };

  return (
    <>
      <div>
        <TabbedShowLayout className="max60vh">
          <Tab label="Therapist Ratings">
            <RatingFilters />
            <RatingsReport filters={filters} otherFilters={ratingFilter} />
          </Tab>
          <Tab label="Therapist Tips">
            <TipsFilters />
            <TipsReport filters={filters} otherFilters={tipsFilter} />
          </Tab>
          <Tab label="Hall of Squeezers">
            <HallSqueezerFilters />
            <HallSqueezersReport filters={filters} otherFilters={hallFilter} />
          </Tab>

          {(isSuperAdmin(permissions) ||
            isAdmin(permissions) ||
            isOperatingPartner(permissions) ||
            isShopManager(permissions)) && (
            <Tab label="Cancelled Members">
              <CancelledMembersFilters />
              <CancelMembershipReport
                filters={filters}
                otherFilters={cancelMemFilter}
              />
            </Tab>
          )}
          {(isSuperAdmin(permissions) || isAdmin(permissions)) && (
            <Tab label="Gifts">
              <GiftFilters />
              <GiftsReport filters={filters} otherFilters={giftFilter} />
            </Tab>
          )}
          {(isSuperAdmin(permissions) ||
            isAdmin(permissions) ||
            isOperatingPartner(permissions)) && (
            <Tab label="KPI Scorecard">
              <ScoreCardFilters />
              <ScoreCardReport
                filters={filters}
                otherFilters={scoreCardFilter}
              />
            </Tab>
          )}
          {(isSuperAdmin(permissions) || isAdmin(permissions)) && (
            <Tab label="Distribution">
              <DistributionFilters />
              <DistributionReport
                filters={filters}
                otherFilters={distributionFilter}
              />
            </Tab>
          )}
          {(isSuperAdmin(permissions) || isAdmin(permissions)) && (
            <Tab label="Membership Cross-Location Adjustment">
              <CrossLocationAdjustmentFilterFilters />
              <CrossLocationAdjustmentReport
                filters={filters}
                otherFilters={crossLocationAdjustmentFilter}
              />
            </Tab>
          )}
          {(isSuperAdmin(permissions) ||
            isAdmin(permissions) ||
            isShopManager(permissions) ||
            isOperatingPartner(permissions)) && (
            <Tab label="Members">
              <MembersFilters />
              <MembersReport filters={filters} otherFilters={membersFilter} />
            </Tab>
          )}
          {(isSuperAdmin(permissions) ||
            isAdmin(permissions) ||
            isShopManager(permissions) ||
            isOperatingPartner(permissions)) && (
            <Tab label="Promos">
              <PromosFilters />
              <PromosReport otherFilters={promosFilter} />
            </Tab>
          )}
          {(isSuperAdmin(permissions) ||
            isAdmin(permissions) ||
            isShopManager(permissions) ||
            isOperatingPartner(permissions)) && (
            <Tab label="Demos">
              <DemosFilters />
              <DemosReport reportFilters={demosFilter} />
            </Tab>
            ) }
          {(isSuperAdmin(permissions)||
            isAdmin(permissions) || isOperatingPartner(permissions)) && (
            <Tab label="Payments (BETA)">
              <PaymentReportsFilters />
              {/* <PaymentReport  filters={filters}
                otherFilters={paymentsFilter} /> */}
            </Tab>
          )}
        </TabbedShowLayout>
      </div>
    </>
  );
};
